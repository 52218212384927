import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import Button from '../components/Button';

import config from "../config";

const FeatureGroup = ({children}) => (
  <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>{children}</div>
)

const FeatureItem = ({ title, description, features, price, utm }) => {
  return (
    <div className="card" style={{width: '18rem'}}>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
      <ul className="list-group list-group-flush">
        {
          features.map((text) => (
            <li className="list-group-item">
              <i className="fa fa-check"></i> {text}
            </li>
          ))
        }
      </ul>
      <div className="card-body">
        <span>{price}</span><br/><br/>
        <a href={`${config.endpoints.app}?action=register&utm=${utm}`} className="card-link">Criar conta</a>
      </div>
    </div>
  )
}

const PlansPage = () => (
  <Layout>
    <SEO title="Planos" />
    <Section>
      <div className="container">
        <h2>Planos</h2>
        <span>Confira nossos planos abaixo.</span>
        <br/>
        <br/>

      <FeatureGroup>
        <FeatureItem
          title={(<>Básico <small>(gratuito)</small></>)}
          description='Plano inicial com recursos indispensáveis para se livrar das multas.'
          features={[
            "SEFAZ Estaduais",
            "Operações Relacionadas",
            "Fórum para membros",
            "Fluxo de Caixa",
            "Receita Federal"
          ]}
          price={'R$ 0,00/mês'}
          style={{marginLeft: '10px'}}
          utm="free"
        />
        
        <div style={{marginLeft: '10px'}}>
          <FeatureItem
            title={(<>Premium <small>(profissional)</small></>)}
            description='Todos os benefícios do plano Básico mais alguns recursos exclusivos'
            features={[
              "SEFAZ Estaduais",
              "Operações Relacionadas",
              "Fórum para membros",
              "Fluxo de Caixa",
              "Receita Federal",
              "Calculadoras de ICMS",
              "Consultoria",
              "Dicas de ICMS"
            ]}
            price={'R$ 10,00/mês'}
            utm="premium"
          />
        </div>
      </FeatureGroup>

      <br/>
      <br/>

      <p>
        <span>Todos os planos incluem acesso ao aplicativo </span>
        <a target="_blank" rel="noopener noreferrer" href={config.download.android} alt="Ver app mobile para Android">Android</a>
        <span> e </span> 
        <a target="_blank" rel="noopener noreferrer" href={config.download.ios} alt="Ver app mobile para IOS">IOS</a>
      </p>
      <Button component="a" href={config.endpoints.app} variant="success">Confira já</Button>
    </div>
    </Section>
  </Layout>
)

export default PlansPage
