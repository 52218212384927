import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ component, variant, children, ...restProps }) => {
  restProps.className = `btn btn-${variant}`;
  return React.createElement(component, restProps, children);
}

Button.defaultProps = {
  component: 'button',
  variant: 'primary'
}

Button.propTypes = {
  component: PropTypes.element,
  variant: PropTypes.string
}

export default Button;